import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { MsalProvider, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import Home from './pages/Home';
import './App.css';

function Header() {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const handleSignIn = () => {
    instance.loginRedirect();
  };

  const handleSignOut = () => {
    instance.logoutRedirect();
  };

  return (
    <nav className="navbar">
      <h1 className="navbar-title">My App</h1>
      <div className="navbar-user-info">
        {isAuthenticated ? (
          <>
            <span>Welcome, {accounts[0]?.name}</span>
            <button onClick={handleSignOut} className="navbar-button">
              Sign Out
            </button>
          </>
        ) : (
          <button onClick={handleSignIn} className="navbar-button">
            Sign In
          </button>
        )}
      </div>
    </nav>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
